<template>
  <div class="layout_common customer_chargeback">
    <div class="main_box">
      <div class="main_box_con">
        <searchToChargeback
          @search="search"
          @reset="search"
          @saleArea="saleArea"
        >
          <router-link
            class="link"
            target="_blank"
            :to="{
              name: 'customer-chargeback-model',
              query: {
                page: 'model'
              }
            }"
          >
            退机机型
          </router-link>
          <router-link
            class="link"
            target="_blank"
            :to="{
              name: 'customer-chargeback-duration',
              query: {
                page: 'duration'
              }
            }"
          >
            退机时长
          </router-link>
          <router-link
            class="link"
            target="_blank"
            :to="{
              name: 'customer-chargeback-table',
              query: {
                page: 'table'
              }
            }"
          >
            切换表格模式
          </router-link>
        </searchToChargeback>
        <div class="echarts" v-loading="loading">
          <lineEcharts
            :propData="echartsData"
            :type="type"
            :searchParams="searchParams"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchToChargeback from '@/views/data-center/components/searchToChargeback'
import lineEcharts from './lineEcharts'
import { formatDate, diffDate } from '@/utils/common'
import { chargebackStatistic } from '@/api'
export default {
  components: {
    searchToChargeback,
    lineEcharts
  },
  data () {
    return {
      loading: false,
      storeId: '',
      storeName: '',
      startTime: '',
      endTime: '',
      timeType: 3,
      type: 1,
      level: '',
      cityId: '',
      echartsData: [],
      searchParams: {} // 收集搜索的参数  传到折线图点击跳转需携带参数
    }
  },
  mounted () {},
  methods: {
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.level = level
      this.cityId = cityId
      this.searchParams = {
        storeId: this.storeId,
        storeName: this.storeName,
        timeType: this.timeType,
        type: this.type,
        durationType: this.durationType,
        chargebackCategory: this.chargebackCategory,
        chargebackType: this.chargebackType,
        reason: this.reason,
        level,
        cityId,
        salesArea
      }
      // 缺省默认6个月
      this.endTime = formatDate(new Date()).slice(0, 7)
      this.startTime = diffDate(-5, 'months', true).slice(0, 7)
      this.getList()
    },
    getList () {
      const params = {
        level: this.level,
        cityId: this.cityId,
        type: this.type,
        timeType: this.timeType,
        storeId: this.storeId,
        durationType: this.durationType,
        chargebackCategory: this.chargebackCategory,
        chargebackType: this.chargebackType,
        reason: this.reason,
        start: this.startTime,
        end: this.endTime
      }
      this.loading = true
      chargebackStatistic(params).then(res => {
        this.loading = false
        this.echartsData = res.data
      })
    },
    // 选择的商家
    selectStore (val) {
      this.storeId = val.storeId
      this.storeName = val.storeName
    },
    close () {
      this.isShow = false
    },
    search (val) {
      const {
        level,
        cityId,
        salesArea,
        type,
        timeType,
        storeId,
        storeName,
        startTime,
        endTime,
        durationType,
        chargebackCategory,
        chargebackType,
        reason
      } = val
      this.level = level
      this.cityId = cityId
      this.type = type
      this.timeType = timeType
      this.storeId = storeId
      this.startTime = startTime
      this.endTime = endTime
      this.durationType = durationType
      this.chargebackCategory = chargebackCategory
      this.chargebackType = chargebackType
      this.reason = reason
      this.searchParams = {
        storeId,
        storeName,
        timeType,
        level,
        cityId,
        salesArea,
        type,
        durationType,
        chargebackCategory,
        chargebackType,
        reason
      }
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.customer_chargeback {
  .echarts {
    height: calc(100% - 120px);
  }
  .link {
    font-size: 16px;
    color: #385bff;
    font-weight: 500;
    margin-left: 20px;
  }
}
</style>
