var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout_common customer_chargeback"},[_c('div',{staticClass:"main_box"},[_c('div',{staticClass:"main_box_con"},[_c('searchToChargeback',{on:{"search":_vm.search,"reset":_vm.search,"saleArea":_vm.saleArea}},[_c('router-link',{staticClass:"link",attrs:{"target":"_blank","to":{
            name: 'customer-chargeback-model',
            query: {
              page: 'model'
            }
          }}},[_vm._v(" 退机机型 ")]),_c('router-link',{staticClass:"link",attrs:{"target":"_blank","to":{
            name: 'customer-chargeback-duration',
            query: {
              page: 'duration'
            }
          }}},[_vm._v(" 退机时长 ")]),_c('router-link',{staticClass:"link",attrs:{"target":"_blank","to":{
            name: 'customer-chargeback-table',
            query: {
              page: 'table'
            }
          }}},[_vm._v(" 切换表格模式 ")])],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"echarts"},[_c('lineEcharts',{attrs:{"propData":_vm.echartsData,"type":_vm.type,"searchParams":_vm.searchParams}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }