<template>
  <div id="chargeback_box" style="width:100%;height:100%">
    <div id="chargeback" style="width:100%;height:100%"></div>
    <notData v-if="propData.length == 0" />
  </div>
</template>
<script>
export default {
  props: {
    propData: {
      type: Array,
      default: () => []
    },
    type: {
      type: Number,
      default: 1
    },
    searchParams: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      data: []
    }
  },
  watch: {
    propData: {
      handler (n) {
        this.data = n
        this.init()
      },
      deep: true
    }
  },
  mounted () {
    this.init()
    window.addEventListener('resize', this.resizeCharts, true)
    this.$erd('chargeback_box', 'chargeback')
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeCharts, true)
  },
  methods: {
    resizeCharts () {
      const myChart = this.$echarts.init(document.getElementById('chargeback'))
      myChart.resize()
    },
    // 不同的type显示不同的文案
    handleType (type) {
      let str = ''
      switch (type) {
        case 1:
          str = '退单台数'
          break
        case 2:
          str = '退单订单'
          break
        case 3:
          str = '退单客户'
          break
      }
      return str
    },
    init () {
      const myChart = this.$echarts.init(document.getElementById('chargeback'))
      const dataX = this.data.map(item => item.dateSubscript)
      const dataY = this.data.map(item => item.rentNum)
      const option = {
        grid: {
          left: '2%',
          right: '2%',
          bottom: '1%',
          containLabel: true
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,.7)',
          padding: 0,
          confine: true,
          formatter: (params) => {
            const relVal = `<div style="
                    border-bottom:1px solid #A8A8A8;
                    line-height:36px;
                    color:#fff;
                    padding:0 10px;">
                    ${params[0].name}
                    <span style="color:#FF7F00">点击查看原因</span>
                </div>
                <div style="line-height:36px;padding:0 10px;color:#fff;">
                    <div>
                      ${this.handleType(this.type)}:&nbsp;&nbsp;
                      <span style="float:right">${
                        params[0].value
                      }</span>
                    </div>
                    <div>
                      退单时长:&nbsp;&nbsp;
                      <span style="float:right">${
                        this.data[params[0].dataIndex].rentDuration
                      }台.天</span>
                    </div>
                    <div>
                      设备价值:&nbsp;&nbsp;
                      <span style="float:right">${
                        this.data[params[0].dataIndex].rentWithdrawalPrice
                      }</span>
                    </div>
                    <div>
                      损失租金:&nbsp;&nbsp;
                      <span style="float:right">${
                        this.data[params[0].dataIndex].lossRent
                      }</span>
                    </div>
                </div>
                `
            return relVal
          }
        },
        xAxis: [{
          type: 'category',
          axisLabel: {
            color: '#1B1C29',
            fontSize: 12,
            margin: 10
          },
          axisTick: {
            // 坐标轴刻度相关设置。
            show: false
          },
          axisLine: {
            // 坐标轴轴线相关设置
            lineStyle: {
              type: 'dashed',
              color: '#e6ecf0'
            }
          },
          splitLine: {
            // 坐标轴在 grid 区域中的分隔线。
            show: false
          },
          data: dataX
        }],
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#ddd'
            }
          }
        }],
        series: [{
          data: dataY,
          type: 'line',
          label: {
            normal: {
              show: true,
              color: '#000',
              position: 'top'
            }
          },
          markLine: {
            silent: true
          },
          itemStyle: {
            normal: {
              color: '#fac858'
            }
          },
          lineStyle: {
            width: 1,
            color: '#fac858'
          }
        }]
      }
      myChart.setOption(option)
      // 点击事件
      myChart.getZr().on('click', (params) => {
        const pointInPixel = [params.offsetX, params.offsetY]
        if (myChart.containPixel('grid', pointInPixel)) {
          var pointInGrid = myChart.convertFromPixel({ seriesIndex: 0 }, pointInPixel)
          // X轴序号
          var xIndex = pointInGrid[0]
          // 获取当前图表的option
          var op = myChart.getOption()
          // 获得图表中我们想要的数据
          var time = op.xAxis[0].data[xIndex]
          // 跳转分析页面
          const {
            storeId,
            storeName,
            timeType,
            level,
            cityId,
            salesArea,
            type,
            durationType,
            chargebackCategory,
            chargebackType,
            reason
          } = this.searchParams
          const route = this.$router.resolve({
            name: 'customer-chargeback-reason',
            query: {
              storeId,
              storeName,
              timeType,
              level,
              cityId,
              salesArea: salesArea.join(','),
              time,
              type,
              durationType,
              chargebackCategory,
              chargebackType,
              reason
            }
          })
          window.open(route.href, '_blank')
        }
      })
      // 将可以响应点击事件的范围内，鼠标样式设为pointer--------------------
      myChart.getZr().on('mousemove', function (params) {
        var pointInPixel = [params.offsetX, params.offsetY]
        if (myChart.containPixel('grid', pointInPixel)) {
          myChart.getZr().setCursorStyle('pointer')
        };
      })
      myChart.on('mouseout', function (params) {
        var pointInPixel = [params.offsetX, params.offsetY]
        if (!myChart.containPixel('grid', pointInPixel)) {
          myChart.getZr().setCursorStyle('default')
        };
      })
    }
  }
}
</script>
